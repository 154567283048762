import {useState, useContext, createContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import storage from 'utils/storage'

import auth_api from 'libs/endpoints/auth'
import users from '../libs/endpoints/users'
import {useFirebase} from 'contexts/FirebaseContext'

AuthProvider.propTypes = {
  children: PropTypes.node
}

const authContext = createContext({})

export function AuthProvider({children}) {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const [authToken, setAuthToken] = useState(null)
  const [currentUser, setCurrentUser] = useState({})
  const {getFcmToken, deleteFirebaseToken} = useFirebase()
  const [fCMToken, setFCMToken] = useState(storage.getFCMToken() ?? null)
  const [fCMFailCount, setFCMFailCount] = useState(0)

  useEffect(() => {
    const fetchAndStoreFcmToken = async () => {
      try {
        if (!fCMToken) {
          console.log('FCM token is not available, fetching a new one')
          const newFcmToken = await getFcmToken()

          if (newFcmToken) {
            storage.storeFCM(newFcmToken)
            setFCMToken(newFcmToken)
            if (currentUser) {
              const currentUserFCMToken = await users.get_active_fcm_token(currentUser._id)
              const fcmTokensArray = currentUserFCMToken.data.fcmTokens.map((tokenObj) => tokenObj.fcmToken)
              if (!currentUserFCMToken || !fcmTokensArray.includes(fCMToken)) {
                await users.subscribe_fcm_token(currentUser._id, fCMToken, 'pwa')
                console.log('Subscribed FCM token for user:', currentUser._id)
              } else {
                console.log('Failed to subscribe user token', currentUser._id)
              }
            }
          } else {
            console.log('Failed to fetch new FCM token')
            return
          }
        } else {
          console.log('FCM token already exists:', fCMToken)
          if (!storage.getFCMToken()) storage.storeFCM(fCMToken)
          if (currentUser) {
            const currentUserFCMToken = await users.get_active_fcm_token(currentUser._id)
            const fcmTokensArray = currentUserFCMToken.data.fcmTokens.map((tokenObj) => tokenObj.fcmToken)
            if (!currentUserFCMToken || !fcmTokensArray.includes(fCMToken)) {
              await users.subscribe_fcm_token(currentUser._id, fCMToken, 'pwa')
              console.log('Subscribed FCM token for user:', currentUser._id)
            } else {
              console.log('Failed to subscribe user token', currentUser._id)
            }
          }
        }
      } catch (error) {
        console.error('Error fetching or storing FCM token:', error)
      }
    }

    if (!window.ReactNativeWebView?.postMessage) {
      const intervalId = setInterval(async () => {
        const existingToken = storage.getFCMToken()

        if (existingToken) {
          clearInterval(intervalId) // Stop the interval if token is already in local storage
          return
        }

        if (fCMFailCount > 5) {
          clearInterval(intervalId) // Stop the interval if token is already in local storage
          return
        }

        fetchAndStoreFcmToken()
        setFCMFailCount((prev) => prev + 1)
      }, 15000) // Fetch every 5 seconds
      fetchAndStoreFcmToken()
    }
  }, [fCMToken, currentUser, authToken])

  const signOut = async () => {
    let postMessageData = {
      type: 'LOGOUT',
      payload: ''
    }

    if (window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify(postMessageData))
    } else {
      const fcm = storage.getFCMToken()
      if (fcm) {
        try {
          await users.deleteDeviceFcmToken(currentUser._id, fcm)
          await deleteFirebaseToken(fcm)
        } catch (e) {
          console.log(e)
        }
      }
    }
    await storage.remove()
    setAuthToken(null)
  }

  const signIn = async ({email, password}) => {
    try {
      // Check for missing fields
      if (!email || !password)
        return {
          msg: 'Missing fields',
          status: false
        }

      // Call the API to sign in
      const result = await auth_api.sign_in_email({email: email, password: password})
      console.log('🚀 ~ signIn ~ result:', result)

      // Check for server or network-related issues (e.g., no response, timeout)
      if (result.problem === 'NETWORK_ERROR') {
        return {
          msg: 'Network error, please try again later.',
          status: false
        }
      }
      if (result.problem === 'SERVER_ERROR') {
        return {
          msg: 'Server error, please try again later.',
          status: false
        }
      }
      if (result.problem === 'TIMEOUT_ERROR') {
        return {
          msg: 'Server Timeout, please try again later.',
          status: false
        }
      }
      // Handle invalid credentials
      if (!result.ok) {
        return {
          msg: 'Invalid credentials',
          status: false
        }
      }

      let data = result.data
      console.log('🚀 ~ signIn ~ data:', data)

      // Handle successful login
      if (data) {
        let user = {
          accountType: data?.accountType,
          createdAt: data?.createdAt,
          dateCreated: data?.dateCreated,
          deviceId: data?.deviceId,
          email: data?.email,
          isActive: data?.isActive,
          isAvailable: data?.isAvailable,
          isVerified: data?.isVerified,
          isCityUpdated: data?.isCityUpdated,
          isNotifOn: data?.isNotifOn,
          isGcashUpdated: data?.isGcashUpdated,
          photo: data?.photo,
          phone: data?.phone,
          verificationCode: data?.verificationCode,
          name: data?.name,
          firstName: data?.firstName,
          lastName: data?.lastName,
          _id: data?._id,
          adminStatus: data?.adminStatus,
          adminStatusRemarks: data?.adminStatusRemarks
        }
        setCurrentUser(user)
        setAuthToken(data.token)

        storage.storeUser(user)
        storage.storeRefreshToken(data.refreshToken)
        storage.storeToken(data.token)

        let postMessageData = {
          type: 'LOGIN',
          payload: data
        }

        if (window.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage(JSON.stringify(postMessageData))
        }

        return {
          msg: 'Success Login',
          status: true
        }
      }

      // Fallback for invalid credentials
      return {
        msg: 'Invalid credentials',
        status: false
      }
    } catch (error) {
      // Handle server crash or unexpected error
      console.error('Error during sign-in:', error)
      return {
        msg: 'Server error, please try again later.',
        status: false
      }
    }
  }

  const socialSignIn = async (form_data) => {
    let data = form_data
    if (data) {
      let user = {
        accountType: data?.accountType,
        createdAt: data?.createdAt,
        dateCreated: data?.dateCreated,
        deviceId: data?.deviceId,
        email: data?.email,
        isActive: data?.isActive,
        isAvailable: data?.isAvailable,
        isVerified: data?.isVerified,
        isCityUpdated: data?.isCityUpdated,
        photo: data?.photo,
        phone: data?.phone,
        verificationCode: data?.verificationCode,
        name: data?.name,
        firstName: data?.firstName,
        lastName: data?.lastName,
        _id: data?._id
      }
      setCurrentUser(user)
      setAuthToken(data.token)

      storage.storeUser(user)
      storage.storeRefreshToken(data.refreshToken)
      storage.storeToken(data.token)

      // let postMessageData = {
      //   type: 'LOGIN',
      //   payload: data
      // }

      // if (window.ReactNativeWebView?.postMessage) {
      //   window.ReactNativeWebView.postMessage(JSON.stringify(postMessageData))
      // }

      if (form_data.newAccount) {
        return {
          msg: 'Success Login',
          status: true,
          new_account: true
        }
      }

      return {
        msg: 'Success Login',
        status: true
      }
    }
    return {
      msg: 'Something went wrong...',
      status: false
    }
  }

  const isSignedIn = () => {
    if (authToken) {
      return true
    } else {
      return false
    }
  }

  const sessionToken = (token) => {
    setAuthToken(token)
  }

  const sessionUser = () => {
    const store_user = storage.getUser()
    store_user && setCurrentUser(JSON.parse(store_user))
  }

  return {
    signIn,
    socialSignIn,
    signOut,
    isSignedIn,
    setCurrentUser,
    currentUser,
    sessionToken,
    sessionUser
  }
}
