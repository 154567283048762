import React from 'react'
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const UpdatePopup = ({open}) => {
  if (!open) return null

  const handleReload = () => {
    window.location.reload(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="update-dialog-title"
      aria-describedby="update-dialog-description"
      PaperProps={{
        style: {
          position: 'absolute',
          overflow: 'hidden'
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        id="update-dialog-title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6">Starjobs has been updated.</Typography>
        <IconButton aria-label="close" onClick={handleReload} style={{color: 'gray'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          A new version is available. Please restart/reload the app to get the latest updates. You may need to close and
          reopen the app from your recent apps list.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default UpdatePopup
