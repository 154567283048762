import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Button,
  TextField,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Autocomplete,
  Paper,
  Input
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {capitalCase} from 'change-case'
import onboard_api from 'libs/endpoints/onboard'

import {useSnackbar} from 'notistack'
import axios from 'axios'

// icons
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RemoveCircleOutlineIcon from '@mui/icons-material/CloseOutlined'

import {styled} from '@mui/material/styles'
const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root.Mui-disabled': {
    // backgroundColor: 'lightgray',
    color: 'red'
  }
})

import {endShift_calculations} from 'utils/gigComputation'
import {drop, pick} from 'lodash'
import config from 'utils/config'

const image_bucket = config.aws.s3UploadUrl

const ConfirmEndShiftNotificationRider = ({open, gig, onCommit, handleClose, loading, branches}) => {
  const {fees, ridersFee, dropoffList, dropOffDetails} = gig
  // const [workTime, setWorkTime] = useState(gig?.hours)
  const [workTime, setWorkTime] = useState()
  const [late, setLate] = useState('')
  const [extension, setExtension] = useState('')
  const [nightSurge, setNightSurge] = useState('')
  const [checked, setChecked] = useState(false)

  const [rate, setRate] = useState(undefined)
  const [isFinal, setIsFinal] = useState(false)
  const [limit, setLimit] = useState(0)
  const [arrBranches, setBranches] = useState([])
  const [arrPickups, setPickups] = useState([])
  const [dropBranches, setDropBranches] = useState([])
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setLoading] = useState(false)

  const [Name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [selectedAddress, setSelectedAddress] = useState({})
  const [isEditingPickup, setIsEditingPickup] = useState(false)
  const [editIndex, setEditIndex] = useState(0)

  const [dropoffDetails, setDropoffDetails] = useState([
    {timeArrived: '', timeFinnished: '', totalTime: '', address: '', timeStamp: ''}
  ])
  const [pickupDetails, setPickupDetails] = useState([
    {timeArrived: '', timeFinnished: '', totalTime: '', address: '', timeStamp: ''}
  ])
  const [openDialog, setOpenDialog] = useState(false)
  const [addressDetails, setAddressDetails] = useState(null)

  const [timeArrived, setTimeArrived] = useState('')
  const [timeFinnished, setTimeFinnished] = useState('')
  const [totalTime, setTotalTime] = useState('')
  const [timeStamp, setTimeStamp] = useState('')
  const [deliveryProof, setDeliveryProof] = useState([])
  const [existingDeliveryProof, setExistingDeliveryProof] = useState([])
  const [address, setAddress] = useState('')
  const [isReturnToPickup, setIsReturnToPickup] = useState(false)
  const [totalWaitTime, setTotalWaitTime] = useState(0)
  const [updatedRidersFee, setUpdatedRidersFee] = useState({})
  const [remarks, setRemarks] = useState('')

  // Handle "Return to Pickup" checkbox change
  const handleReturnToPickup = async (event) => {
    setIsReturnToPickup(event.target.checked)

    if (event.target.checked && gig.pickup) {
      const returnToPickupDetail = {
        timeArrived: '',
        timeFinished: '',
        totalTime: '',
        address: {
          label: gig.pickup.address,
          value: gig.pickup.address,
          lat: gig.pickup.lat,
          long: gig.pickup.long
        },
        timeStamp: new Date().toISOString()
      }

      // Use callback to ensure latest state
      setDropoffDetails((prevDetails) => {
        const updatedDetails = [...prevDetails, returnToPickupDetail]
        return updatedDetails
      })
    } else {
      // Use callback for filtering as well
      setDropoffDetails((prevDetails) => {
        const filteredDetails = prevDetails.filter((detail) => detail.address.value !== gig.pickup.address)
        return filteredDetails
      })
    }
  }
  // UseEffect to calculate the distance whenever dropoffDetails change
  useEffect(async () => {
    if (gig.pickup && dropoffDetails.length > 0) {
      const pickupCoordinates = gig.pickup // Directly use lat/long object
      const dropOffCoordinates = dropoffDetails.map((dropOff) => dropOff.address) // Keep as objects

      // Calculate distance (using Haversine formula, for example)
      const totalDistance = await calculateDistance(pickupCoordinates, dropOffCoordinates)

      // Recalculate expected payment
      const perKMFee = parseFloat(ridersFee.gigRatePerKm) * totalDistance
      const addStopFee = 30 * dropOffCoordinates.length
      const expectedPayment = parseFloat(ridersFee.baseFare) + perKMFee + addStopFee + totalWaitTime

      const newRidersFee = {
        baseFare: ridersFee.baseFare,
        allowance: ridersFee.allowance,
        gigRatePerKm: ridersFee.gigRatePerKm,
        perKmFee: perKMFee,
        addPerDrop: addStopFee,
        expectedPayment: expectedPayment,
        totalKm: totalDistance,
        totalWaitingTimePay: totalWaitTime
      }

      setUpdatedRidersFee(newRidersFee)
      setExpectedGigFee(expectedPayment)
    }
  }, [dropoffDetails, gig.pickup, totalWaitTime])
  const calculateDistance = async (pickup, dropOffs) => {
    if (!pickup || !dropOffs.length) return 0

    const apiKey = '5b3ce3597851110001cf6248fcb634678f544fa09ceee3f7b79d5d66'

    try {
      // Construct the coordinates array: [[pickupLongitude, pickupLatitude], [dropOffLongitude, dropOffLatitude], ...]
      const coordinates = [
        [pickup.long, pickup.lat], // Pickup location
        ...dropOffs.map((dropOff) => [dropOff.long, dropOff.lat]) // Each drop-off location
      ]

      // Make the API request to OpenRouteService
      const response = await axios.post(
        'https://api.openrouteservice.org/v2/directions/driving-car',
        {
          coordinates: coordinates
        },
        {
          headers: {
            Authorization: apiKey,
            'Content-Type': 'application/json'
          }
        }
      )

      // Extract distance from the response
      const distance = response.data.routes[0].summary.distance // Distance in meters

      return distance / 1000 // Return the total distance in kilometers
    } catch (error) {
      console.error('Error fetching distance data:', error)
      return 0
    }
  }
  const handleAddPoint = (isPickup) => {
    setDropoffDetails([
      ...dropoffDetails,
      {timeArrived: '', timeFinnished: '', totalTime: '', address: {label: '', value: ''}, timeStamp: ''}
    ])
  }

  const handleRemovePoint = (isPickup, index) => {
    if (isPickup) {
      const updatedPickupDetails = pickupDetails.filter((_, i) => i !== index)
      setPickupDetails(updatedPickupDetails)
    } else {
      const updatedDropoffDetails = dropoffDetails.filter((_, i) => i !== index)
      setDropoffDetails(updatedDropoffDetails)
    }
  }

  const handleOpenDialog = (isPickup, index) => {
    setIsEditingPickup(isPickup)
    setEditIndex(index)
    setOpenDialog(true)

    // If editing existing entry, pre-fill details
    if (isPickup) {
      const pickup = pickupDetails
      setAddress(pickup.address || '')
      setTimeArrived(pickup.timeArrived || '')
      setTimeFinnished(pickup.timeFinnished || '')
      setTotalTime(pickup.totalTime || '')
      setTimeStamp(pickup.timeStamp || '')
    } else if (!isPickup && dropoffDetails[index]) {
      const dropoff = dropoffDetails[index]
      setAddress(dropoff.address || '')
      setTimeArrived(dropoff.timeArrived || '')
      setTimeFinnished(dropoff.timeFinnished || '')
      setTotalTime(dropoff.totalTime || '')
      setTimeStamp(dropoff.timeStamp || '')
    }
  }

  const handleCloseDialogRider = () => {
    setOpenDialog(false)
    clearForm()
  }
  const clearForm = () => {
    setAddress('')
    setTimeArrived('')
    setTimeFinnished('')
    setTotalTime('')
  }

  // Helper function to convert "HH:MM" to total minutes
  const getTotalMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number)
    return hours * 60 + minutes
  }

  const handleSaveDialog = () => {
    let updatedTotalWaitingTime = totalWaitTime

    // Check if the dropoff already has a waiting time
    const hasExistingWaitingTime = editIndex !== null && dropoffDetails[editIndex]?.totalTime

    // If no existing waiting time, calculate additional amount based on totalTime
    if (!hasExistingWaitingTime && totalTime) {
      const totalMinutes = getTotalMinutes(totalTime)
      const additionalAmount = Math.floor(totalMinutes / 30) * 60
      updatedTotalWaitingTime += additionalAmount
    }

    // Validate the file type
    const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
    if (timeStamp && !validFileTypes.includes(timeStamp.type)) {
      enqueueSnackbar('Invalid file type. Only JPG, PNG, and PDF are allowed.', {
        variant: 'error'
      })
      return
    }

    // Validate required fields (timeArrived, timeFinished, address, timeStamp)
    if (!timeArrived || !timeFinnished || !address || !timeStamp) {
      enqueueSnackbar(
        'Please fill in all required fields: Time Arrived, Time Finished, Address, and Upload Time Stamp.',
        {
          variant: 'error'
        }
      )
      return
    }

    // Validate that timeArrived is before timeFinnished
    const arrivedTime = new Date(`1970-01-01T${timeArrived}:00`)
    const finishedTime = new Date(`1970-01-01T${timeFinnished}:00`)
    if (arrivedTime >= finishedTime) {
      enqueueSnackbar('Time Finished should be after Time Arrived.', {
        variant: 'error'
      })
      return
    }

    if (isEditingPickup) {
      // Update the pickup details directly
      setPickupDetails({
        timeArrived: timeArrived,
        timeFinnished: timeFinnished,
        totalTime: totalTime,
        address: address,
        timeStamp: timeStamp
      })
    } else {
      // Update the dropoff details
      const newDetails = [...dropoffDetails]
      if (editIndex !== null) {
        newDetails[editIndex] = {
          timeArrived: timeArrived,
          timeFinnished: timeFinnished,
          totalTime: totalTime,
          address: address,
          timeStamp: timeStamp
        }
      } else {
        newDetails.push({
          timeArrived: timeArrived,
          timeFinnished: timeFinnished,
          totalTime: totalTime,
          address: address,
          timeStamp: timeStamp
        })
      }

      setDropoffDetails(newDetails)
    }

    // Update the total wait time
    setTotalWaitTime(updatedTotalWaitingTime)

    // Close the dialog after saving
    handleCloseDialogRider()
  }

  useEffect(() => {
    if (arrBranches.length > 0) {
      // For Dropoffs
      if (gig.dropOffDetails && gig.dropOffDetails.length > 0) {
        const initializedDropoffs = gig.dropOffDetails.map((dropOff) => {
          const matchedBranch = arrBranches.find(
            (branch) => branch.label === dropOff.address || branch.value === dropOff.address
          )

          return {
            timeArrived: dropOff.timeArrived || '',
            timeFinnished: dropOff.timeDeparture || '',
            totalTime: dropOff.waitingTime || '',
            address: matchedBranch || {label: '', addressInfo: ''}, // Replace with the matched branch or a default
            timeStamp: dropOff.proof || timeStamp
          }
        })

        setDropoffDetails(initializedDropoffs)
      }

      // For Pickup
      if (gig.pickup) {
        const matchedPickupBranch = arrPickups.find(
          (index) => index.label === gig.pickup?.address || index.value === gig.pickup.address
        )

        const initializedPickup = {
          timeArrived: gig.pickup.timeArrived || '',
          timeFinnished: gig.pickup.timeDeparture || '',
          totalTime: gig.pickup.waitingTime || '',
          address: matchedPickupBranch || {label: '', addressInfo: ''},
          timeStamp: gig.pickup.proof || timeStamp
        }

        setPickupDetails(initializedPickup)
      }
      if (gig.deliveryProof) {
        setExistingDeliveryProof(Array.isArray(gig.deliveryProof) ? gig.deliveryProof : [gig.deliveryProof])
      }
    }
  }, [gig, arrBranches, arrPickups])
  const hoursLimit = () => {
    if (fees) {
      return gig.hours < 8 ? 9 : parseInt(gig.hours) + 1
    }
    // return 24
    return 9
  }

  const handleCommit = async (values) => {
    setChecked(false)
    //
    let uploadedFiles = {}
    let hasInvalidFile = false

    try {
      let data = {
        new_status: 'End-Shift',
        dropoffDetails: dropoffDetails ?? [],
        pickupDetails: pickupDetails ?? [],
        deliveryProof: deliveryProof ?? [],
        uploadedFiles,
        updatedRidersFee: updatedRidersFee ?? {},
        remarks: remarks ?? '',
        ...values
      }

      console.log(data, 'DATA')
      // uncomment onCommit for testing
      onCommit(data)
    } catch (error) {
      console.log(error)
    }
  }

  const formatDecimal = (value, type) => {
    if (!value) return
    if (type === 'hours') {
      setWorkTime(parseFloat(value).toFixed(2))
    }
    if (type === 'late') {
      setLate(parseFloat(value).toFixed(2))
    }
    if (type === 'extension') {
      setExtension(parseFloat(value).toFixed(2))
    }
    if (type === 'nightSurge') {
      setNightSurge(parseFloat(value).toFixed(2))
    }
  }

  const handleTime = (value) => {
    const absoluteHours = Math.floor(Math.abs(value))
    const minutes = Math.floor((Math.abs(value) * 60) % 60)

    return (
      <>
        {' about your new worktime '}
        <br></br>
        <b style={{color: '#0d7bc9'}}>{`${absoluteHours} hours ${minutes} mins`}</b>
        <br></br>
      </>
    )
  }

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  const handleCloseDialog = () => {
    handleClose()
    setIsNotComplete(true)
  }

  // Dialog variables
  const [expectedGigFee, setExpectedGigFee] = useState('')
  const [isNotComplete, setIsNotComplete] = useState(true)

  const parseTime = (time) => {
    const hours = Math.floor(time)
    const minutes = Math.round((time - hours) * 60)
    return {hours, minutes}
  }

  const isEmpty = () => {
    return dropoffDetails.some(
      (detail) =>
        detail.timeArrived === '' ||
        detail.timeFinnished === '' ||
        detail.totalTime === '' ||
        detail.address.label === ''
    )
  }

  const handleHoursChange = (event, setter) => {
    setter(event.target.value)
  }

  const handleMinutesChange = (event, setter) => {
    setter(event.target.value)
  }
  useEffect(() => {
    if (gig) {
      const {ridersFee} = gig
      console.log('🚀 ~ useEffect ~ ridersFee:', ridersFee)
      setLimit(hoursLimit())

      if (!isEmpty()) {
        setIsNotComplete(false)
      }

      //   const {jobsterFinal} = endShift_calculations(
      //     calculatedWorktime,
      //     gig?.fee,
      //     fees?.voluntaryFee,
      //     fees?.premiumFee,
      //     fees?.holidaySurge,
      //     convertedLate,
      //     calculatedExtension,
      //     calculatedNightSurge
      //   )
      // let expPay = parseFloat(ridersFee?.expectedPayment) || 0
      // setExpectedGigFee(expPay.toFixed(2))
    }
  }, [dropoffDetails, pickupDetails, address, timeArrived, timeFinnished, totalTime])

  const calculateTotalTime = (timeArrived, timeFinnished) => {
    if (!timeArrived || !timeFinnished) return ''

    const [arrivedHours, arrivedMinutes] = timeArrived.split(':').map(Number)
    const [finishedHours, finishedMinutes] = timeFinnished.split(':').map(Number)

    // Create Date objects for both times
    const arrivedTime = new Date()
    const finishedTime = new Date()

    arrivedTime.setHours(arrivedHours, arrivedMinutes)
    finishedTime.setHours(finishedHours, finishedMinutes)

    // Calculate the difference in milliseconds
    const difference = finishedTime - arrivedTime

    // Convert the difference to hours and minutes
    const totalMinutes = Math.floor(difference / (1000 * 60))
    const totalHours = Math.floor(totalMinutes / 60)
    const remainingMinutes = totalMinutes % 60

    // Format the result as "HH:MM"
    const formattedTotalTime = `${String(totalHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`

    return formattedTotalTime
  }

  // Inside your component
  useEffect(() => {
    const total = calculateTotalTime(timeArrived, timeFinnished)
    console.log('🚀 ~ useEffect ~ total:', total)
    setTotalTime(total)
  }, [timeArrived, timeFinnished])

  useEffect(() => {
    const formatBranches = () => {
      let arrBranches = []
      let arrPickups = []
      branches.map((item) => {
        // arrArea.push({value: item, label: capitalCase(item)})
        arrBranches.push({
          value: item.name,
          label: capitalCase(item.name),
          route: item.route,
          lat: item.lat,
          long: item.long,
          location: item.location
        })
      })

      // pickups
      const storedPickups = JSON.parse(localStorage.getItem('pickups')) || []

      // Transform the pickups data
      arrPickups = storedPickups.map((item) => ({
        value: item.name,
        label: capitalCase(item.name),
        lat: item.lat,
        long: item.long,
        location: item.location
      }))

      arrBranches = [...arrBranches, ...arrPickups]

      setBranches(arrBranches)
      setPickups(arrPickups)
    }

    formatBranches()
  }, [branches])
  useEffect(() => {
    const formatDropBranches = () => {
      let dropBranches = []

      if (dropoffList) {
        dropoffList.map((item) => {
          // arrArea.push({value: item, label: capitalCase(item)})
          dropBranches.push({
            value: item.address,
            label: capitalCase(item.address),
            route: item.route,
            lat: item.lat,
            long: item.long
            // location: item.location
          })
        })
      }
      setDropBranches(dropBranches)
    }
    formatDropBranches()
  }, [dropoffList])

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseDialog()
          }
        }}
        sx={{'& .MuiDialog-paper': {minWidth: '300px', maxWidth: '900px', width: '80%'}}}
      >
        <Button sx={{ml: 'auto', pt: 2}} onClick={handleCloseDialog}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" component="span">
            Confirm End-Shift
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Box border={1} borderColor="primary.main" sx={{p: 2, mb: 2, borderRadius: 1}}>
            <Box sx={{mb: 2, display: 'flex', alignItems: 'center'}}>
              <RadioButtonUncheckedIcon color="warning" sx={{mr: 1}} />
              {pickupDetails.address ? (
                <Box onClick={() => handleOpenDialog(true)} sx={{cursor: 'pointer'}}>
                  <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                    {gig.pickup?.address}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Lat: {gig.pickup?.lat}, Lng: {gig.pickup?.long}
                  </Typography>
                </Box>
              ) : (
                <Button sx={{color: 'text.secondary'}} onClick={() => handleOpenDialog(true)}>
                  Enter pickup details
                </Button>
              )}
            </Box>
            <Divider style={{width: '100%', marginBottom: 20}} />
            {/* Dropoff Details */}
            {dropoffDetails.map((detail, index) => (
              <Box key={index} sx={{mb: 2, display: 'flex', alignItems: 'center'}}>
                <LocationOnIcon color="primary" sx={{mr: 1}} />
                {detail.address.label ? (
                  <Box onClick={() => handleOpenDialog(false, index)} sx={{cursor: 'pointer'}}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                      {detail.address.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Lat: {detail.address.lat}, Lng: {detail.address.long}
                    </Typography>
                  </Box>
                ) : (
                  <Button sx={{color: 'text.secondary'}} onClick={() => handleOpenDialog(false, index)}>
                    Enter drop-off details
                  </Button>
                )}
                {/* {index !== 0 && (
                  <IconButton color="error" sx={{ml: 1}} onClick={() => handleRemovePoint(false, index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )} */}
              </Box>
            ))}
            {/* Add Another Point */}

            <Dialog
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleCloseDialog()
                }
              }}
            >
              <DialogTitle>
                <Typography variant="h6" textAlign="center" sx={{mt: 2}}>
                  {isEditingPickup ? 'Enter Pick Up Details' : 'Enter Drop Off Details'}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{mt: 2, mb: 2, mr: 2}}>
                {/* <TextField
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  disabled
                  fullWidth
                  sx={{mb: 2, mt: 1}}
                /> */}
                <Autocomplete
                  options={editIndex > gig.dropOffs?.length - 1 ? arrBranches : dropBranches}
                  getOptionLabel={(option) => option.label}
                  value={address}
                  disabled
                  onChange={(event, newValue) => setAddress(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Drop-off Location" variant="outlined" />
                  )}
                  sx={{mb: 2, mt: 1}}
                  PaperComponent={(props) => <Paper {...props} sx={{zIndex: 1300}} />}
                />
                <TextField
                  label="Time Arrived"
                  type="text"
                  value={timeArrived}
                  fullWidth
                  sx={{mb: 2}}
                  InputLabelProps={{
                    shrink: true // Ensure the label stays above the input when a value is selected
                  }}
                />

                <TextField
                  label="Time Finished"
                  type="text"
                  value={timeFinnished}
                  fullWidth
                  sx={{mb: 2}}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  label="Total Waiting Time"
                  type="text" // Change to 'text' since it's a calculated string
                  value={totalTime}
                  fullWidth
                  sx={{mb: 2}}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Grid container spacing={2}>
                  {/* Display the uploaded file name */}
                  {timeStamp && (
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2">
                        Uploaded Time Stamp:
                        <a href={`${image_bucket}${timeStamp}`} target="_blank" rel="noopener noreferrer">
                          {timeStamp}
                        </a>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button sx={{m: 4}} variant="outlined" onClick={handleCloseDialogRider}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          {/* Return to Pickup Checkbox */}

          <Grid container spacing={2}>
            {/* Show existing uploaded files if available */}
            {existingDeliveryProof && existingDeliveryProof.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Typography variant="body2"> Uploaded Delivery Proof:</Typography>
                <ul>
                  {existingDeliveryProof.map((file, index) => (
                    <li key={index}>
                      <a href={`${image_bucket}${file}`} target="_blank" rel="noopener noreferrer">
                        {file}
                      </a>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}

            {/* Show newly uploaded files if available */}
            {deliveryProof && deliveryProof.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Typography variant="body2">Newly Uploaded Files:</Typography>
                <ul>
                  {Array.from(deliveryProof).map((file, index) => (
                    <li key={index}>
                      <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>
          <Divider sx={{width: '100%', mt: 2, mb: 2, ml: 10}} />

          <Grid sx={{mt: 2}} container>
            {/* Base Fare */}
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Base Fare:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>Php {parseFloat(ridersFee?.baseFare).toFixed(2)}</Typography>
              </Grid>
            </Grid>
            {/* Gig Rate Per Km */}
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Gig Rate Per Km:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>Php {parseFloat(ridersFee?.gigRatePerKm).toFixed(2)}</Typography>
              </Grid>
            </Grid>
            {/* Total Kilometers */}
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Total Kilometers:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>{parseFloat(ridersFee?.totalKm).toFixed(2)} km</Typography>
              </Grid>
            </Grid>

            {/* Total Kilometers */}
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Total KM Fee:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>{parseFloat(ridersFee?.perKmFee).toFixed(2)}</Typography>
              </Grid>
            </Grid>

            {/* Additional Per Drop */}
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Additional Per Drop:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>Php {parseFloat(ridersFee?.addPerDrop).toFixed(2)}</Typography>
              </Grid>
            </Grid>

            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Total Waiting Time Pay:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>Php {parseFloat(ridersFee?.totalWaitingTimePay).toFixed(2)}</Typography>
              </Grid>
            </Grid>

            {/* Allowance */}
            <Grid item container alignItems="center">
              <Grid item xs={6}>
                <Typography>Allowance:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography>Php {parseFloat(updatedRidersFee?.allowance).toFixed(2)}</Typography>
              </Grid>
            </Grid>

            {/* Expected Gig Fee */}
            <Grid item container alignItems="center" sx={{mt: 2}}>
              <Grid item xs={6}>
                <Typography sx={{fontWeight: 'bold'}}>Expected Gig Fee:</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: 'left'}}>
                <Typography sx={{color: 'starjobs.main', fontWeight: 'bold'}}>
                  Php{parseFloat(ridersFee?.expectedPayment).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => setIsFinal(true)}
              loading={loading}
            >
              Confirm End Shift
            </LoadingButton>
            <LoadingButton onClick={handleCloseDialog} size="large" variant="outlined" color="inherit" sx={{mt: 2}}>
              No
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Final confirm dialog */}
      {/* <Dialog open={isFinal} onClick={() => setIsFinal(false)}> */}
      <Dialog open={isFinal}>
        <Button
          sx={{ml: 'auto', pt: 2}}
          onClick={() => {
            setIsFinal(false)
            setChecked(false)
          }}
        >
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Do you confirm to end this Gig?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="body2" sx={{mb: 6, fontWeight: 400, textAlign: 'justify', mx: 'auto'}}>
            Ensure all tasks are completed accurately and efficiently. Check inventories, equipment, and machines for
            damages before confirming a successful gig. <strong>Confirm Only if everything is in order. </strong>
            <Divider sx={{mb: 1, mt: 2, fontWeight: 400, backgroundColor: 'rgba(0, 0, 0, 0.87)'}} />
            Confirmed time records
            <strong> cannot be adjusted</strong>, and gig fees will be released after confirmation. Please ensure that
            all details are correct.
          </Typography>
          <TextField
            id="remarks"
            label="Remarks (Optional)"
            key="remarks"
            name="remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            rows={3}
            type="text"
            fullWidth
            multiline
            sx={{mb: 3, mt: 2}}
          />
          <Box sx={{display: 'inline', width: {xs: '50%', sm: '33%', lg: '33%'}}}>
            <FormControlLabel
              sx={{marginTop: '0 !important'}}
              control={
                <Checkbox
                  id="skills"
                  color="primary"
                  checked={checked}
                  onChange={(e) => handleChange(e)}
                  // value={checked}
                />
              }
              label={
                <Typography variant="body2" align="left" sx={{color: 'text.secondary', marginTop: '0 !important'}}>
                  I confirm that all details are correct
                </Typography>
              }
            />
          </Box>
          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => handleCommit(gig)}
              loading={loading}
              disabled={!checked}
              sx={{textTransform: 'initial !important'}}
            >
              Yes, I have approved the riders fee and work hours.
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                setIsFinal(false)
                setChecked(false)
              }}
              size="large"
              variant="outlined"
              color="inherit"
              sx={{mt: 2}}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmEndShiftNotificationRider.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  gig: PropTypes.array,
  onCommit: PropTypes.func,
  handleClose: PropTypes.func,
  onReject: PropTypes.func,
  loading: PropTypes.bool,
  branches: PropTypes.array,
  pickups: PropTypes.array
}

export default ConfirmEndShiftNotificationRider
